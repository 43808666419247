<script>
export default {
  name: "FooterComponent",
  data(){
    return {
      year: new Date().getFullYear(),
      domain: window.location.host,
    }
  }
}
</script>

<template>
  <footer class="footer">
    <p>© 2018 - {{ year }} {{ domain }} ALL RIGHT RESERVED</p>
  </footer>
</template>

<style scoped>
.footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ebebeb;
  background-color: #ffffff;
}
</style>
