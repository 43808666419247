<script>
export default {
  name: "HeaderComponent",
  data(){
    return{
      logo: require('../../assets/logo.webp'),
    }
  }
}
</script>

<template>
  <nav>
      <router-link to="/" class="logo">
        <el-image :src="logo" fit="cover" style="width: 60px; height: 60px"></el-image>
        <h1>Image Compressor</h1>
      </router-link>
      <router-link to="/">图片转webP</router-link>
      <router-link to="/cropping">图片裁剪</router-link>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 50px;

  position: fixed;
  top: 0;
  z-index: 99;
  padding: 0 30px;
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px #ebebeb;

  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    h1{
      font-size: 32px;
    }
  }
}
</style>
