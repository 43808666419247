<script>
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "@/layout/components/Footer.vue";

export default {
  name: "Layout",
  components: {FooterComponent, HeaderComponent },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
      window.addEventListener('scroll', this.toggleVisibility);
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.toggleVisibility);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({top: 0,behavior: 'smooth'});
    },
    toggleVisibility() {
        this.isVisible = window.pageYOffset > 300;
    }
  }
}
</script>

<template>
  <section class="content">
    <HeaderComponent></HeaderComponent>
    <el-row :gutter="20">
      <el-col :span="5"></el-col>
      <el-col :span="14">
        <router-view class="container"></router-view>
      </el-col>
      <el-col :span="5"></el-col>
    </el-row>
    <transition name="fade">
      <div v-if="isVisible" class="scroll-to-top" @click="scrollToTop">Top</div>
    </transition>
    <FooterComponent/>
  </section>
</template>

<style scoped>
section.content{
  padding: 100px 0 100px;
}
.container {
  margin: 0 auto;
  position: relative;
}

div.scroll-to-top {
  width: 50px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px 2px #cccccc;
  position: sticky !important;
  bottom: 160px;
  left: calc(100vw - 70px);
  display: flex;
  align-items: center;
  justify-content: center;

  span.iconfont {
    font-size: 38px;
    margin-bottom: 5px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
