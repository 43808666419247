import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../layout/index.vue'

const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import("../views/home/index.vue"),
            }
        ]
    },
    {
        path: '/cropping',
        component: Layout,
        children: [
            {
                path: '',
                name: 'Cropping',
                component: () => import('../views/cropping/index.vue'),
            }
        ]
    },
    {
        path: '/:catchAll(.*)', // 捕获所有未匹配的路由
        component: Layout,
        children: [
            {
                path: '',
                name: 'NotFound',
                component: () => import('../views/error/404.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
